<template>
  <div class="h-100 ob-ed">
    <OnboardingLayout :step="step" v-if="!isCompleted">
      <template v-slot:left-section>
        <div class="h-75">
          <h2 class="ob-ed__title">Learning Goals</h2>
          <p class="ob-ed__content">
            In order to provide you unique content we need to learn more about
            your interests.
          </p>
          <div class="d-flex align-items-center h-50">
            <div class="d-flex align-items-start">
              <b-img
                :src="require('@/assets/images/warning.svg')"
                class="mr-2 mt-1"
                alt="warning"
              />
              <p class="ob-ed__helper">
                But why? We will use your background information to generate
                suggestions of interests
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:content-section>
        <div class="w-100 h-100 d-flex flex-wrap">
          <div class="ob-ed__main">
            <h2 class="ob-ed__title mb-3 w-40">
              Pick upto 5 interests
            </h2>
            <p class="ob-ed__sc">
              Suggested learning interests based on your profile:
            </p>
            <div class="ob-ed__search">
              <b-button
                variant="info"
                pill
                class="ob-btn__pill active d-flex align-items-center"
                v-b-modal.search-modal
                >Search<b-img
                  :src="require('@/assets/images/search.svg')"
                  class="ml-2"
                  width="10"
              /></b-button>
            </div>
            <div class="ob-ed__pills">
              <b-button
                v-for="(t, index) in availableTopics"
                :key="index"
                @click="addInterest(t, index)"
                :pressed="t.active"
                variant="info"
                pill
                class="ob-btn__pill"
              >
                {{ t.name }}
                <span>
                  <b-icon
                    :icon="t.active ? 'x' : 'plus'"
                    style="color:#0043AC"
                  ></b-icon
                ></span>
              </b-button>
            </div>
            <div
              :class="{
                'ob-ed__selected mt-5 mb-2': true,
                visible: selectedTopics.length,
                invisible: !selectedTopics.length
              }"
            ></div>
          </div>
          <div class="ob-ed__cta">
            <b-button class="mr-4 ob-btn" @click="isCompleted = true"
              >Skip</b-button
            >
            <b-button
              class="mr-3 ob-btn ob-btn-primary"
              :disabled="disableBtn"
              @click="submitInterests"
              >Next</b-button
            >
            <div
              class="ob-ed__alert d-inline-block"
              v-if="selectedTopics.length > 4"
            >
              <img
                src="@/assets/images/warning.svg"
                alt="warning"
                width="20"
                height="20"
                class="mr-2"
              />
              <span>Max 5 interests can be selected</span>
            </div>
          </div>
        </div>
      </template>
    </OnboardingLayout>
    <div v-else class="h-100">
      <b-row class="p-5 m-0 h-100 ob-success">
        <b-col md="7">
          <div class="h-100 ob-success__div">
            <div class="mb-3">
              <img
                src="@/assets/images/logo/whoa-logo-blue-en.svg"
                alt="Logo"
                width="auto"
                height="48"
              />
            </div>
            <div class="ob-success__img">
              <img
                src="@/assets/images/illustration/illustration-onboarding-completed.svg"
                alt="onboarding-completed"
                width="auto"
                height="auto"
              />
            </div>
          </div>
        </b-col>
        <b-col md="5">
          <div class="h-100 d-flex align-items-center justify-content-center">
            <div class="ob-success__content">
              <h2 class="ob-interest__title">Onboarding completed!</h2>
              <p>Begin your learning now!</p>
              <b-button class="ob-btn ob-btn-primary" @click="onStartLearning"
                >Start Learning</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div>
      <b-modal id="search-modal" centered hide-footer scrollable>
        <template #modal-header="{close}">
          <b-input-group class="mt-0">
            <template #prepend>
              <b-input-group-text>
                <b-img
                  :src="require('@/assets/images/icons/search-grey.svg')"
                  width="17"
                  alt="search"
                  class="c-pointer"
                  height="auto"
                />
              </b-input-group-text>
            </template>
            <b-form-input type="search" v-model="search"></b-form-input>
          </b-input-group>
          <b-button class="ob-btn ob-btn-secondary" @click="close()"
            >Close</b-button
          >
        </template>
        <div>
          <ul>
            <li v-for="(a, index) in unselectedTopics" :key="index">
              <p>{{ a.name }}</p>
              <b-button
                class="ob-btn ob-btn-secondary"
                @click="addInterest(a, index)"
                >Add <b-icon icon="plus" style="color:#0043AC"
              /></b-button>
            </li>
          </ul>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import OnboardingLayout from "../Layout/OnboardingLayout";

export default {
  props: ["step"],
  components: { OnboardingLayout },
  data() {
    return {
      isCompleted: false,
      search: "",
      allTopics: []
    };
  },
  computed: {
    selectedTopics() {
      return this.allTopics.filter(t => t.active);
    },
    disableBtn() {
      return this.selectedTopics.length < 1 || this.selectedTopics.length > 5;
    },
    availableTopics() {
      let arr = [],
        activeInterest = this.allTopics.filter(t => t.active);
      if (activeInterest.length) {
        arr = activeInterest;
      }
      this.allTopics.forEach(t => {
        if (arr.length < 8 && !t.active) {
          arr.push(t);
        }
      });
      return arr;
    },
    unselectedTopics() {
      return this.allTopics.filter(
        t =>
          !t.active &&
          t.name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1
      );
    }
  },
  methods: {
    addInterest(t, index) {
      if (this.selectedTopics.length <= 4 || t.active) {
        t.active = !t.active;
        this.$store.commit("SET_TOPIC", t, index);
      }
    },
    submitInterests() {
      this.$store
        .dispatch("updateProfile", {
          keycloak_id: this.$keycloak.idTokenParsed.sub,
          interests: this.selectedTopics
        })
        .then(() => {
          this.isCompleted = true;
        });
    },
    onStartLearning() {
      this.$router.push({ name: "Dashboard" });
    }
  }
};
</script>
<style lang="scss">
#search-modal {
  .modal-header {
    border-bottom: none;
    align-items: flex-end;
    .input-group {
      border: none;
      .input-group-text {
        border: none;
        background: transparent;
        display: flex;
        align-items: flex-end;
        padding-bottom: 0px;
        padding-right: 10px;
        .b-icon {
          font-size: 14px;
        }
      }
      .form-control {
        border: none;
        border-bottom: 1px solid #e5e5e5;
        padding-left: 0;
        padding-bottom: 0px;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1px;
        color: #25282b;
        box-shadow: none;
      }
    }
    button {
      font-size: 14px;
      padding-bottom: 0;
    }
  }
  .modal-body {
    padding-top: 5px;
    max-height: 400px;
    ul {
      padding: 0px 16px;
      li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e8e8e8;
        padding-bottom: 7px;
        &:last-child {
          border-bottom: 0;
        }
        p {
          margin-bottom: 0;
          width: 80%;
          font-size: 14px;
          line-height: 22px;
          letter-spacing: 0.1px;
          color: rgba(82, 87, 92, 0.7);
        }
        .ob-btn {
          padding-right: 0;
          font-size: 12px;
          width: 20%;
          text-align: right;
        }
      }
    }
  }
}
.modal-backdrop {
  background: #e8e8e8;
}
</style>
